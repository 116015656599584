const LandingPage = () => import("../components/landing/landing.vue");
const Login = () => import("../components/usuario/Login.vue");
const NotFound = () => import("../components/common/NotFound.vue");
const Registro = () => import("../components/usuario/Registro.vue");
//Recuperar contraseña
const ResetPasswordRequest = () => import("../components/auth/ResetPasswordRequest.vue");
const ResetPassword = () => import("../components/auth/ResetPassword.vue");
//EN COMUN
//const UsuarioContactos = () => import('../components/usuario/UsuarioContactos.vue');
//const Movilidad = () => import('../components/common/MovilidadLaboral.vue');
//const Becas = () => import('../components/common/Becas.vue');
const Developer = () => import("../components/common/Dev.vue");
//EMPRESA
const EmpresaLogin = () => import("../components/empresa/EmpresaLogin.vue");
const EmpresaRegistro = () => import("../components/empresa/EmpresaRegistro.vue");
const EmpresaHome = () => import("../components/empresa/EmpresaHome.vue");
//Ofertas
const OfertaInfo = () => import("../components/ofertas/OfertaInfo.vue");
//Usuario Participacion Evento QRCode
const EventoParticipacionQR = () => import("../components/eventos/EventoQRParticipacion.vue");
//SUCURSAL
const SucursalRegistro = () => import("../components/sucursal/SucursalRegistro.vue");
//USUARIO
const UsuarioHome = () => import("../components/usuario/UsuarioHome.vue");
//const UsuarioSeguimiento = () => import("../components/usuario/UsuarioSeguimiento.vue");
//GESTOR
const GestorHome = () => import("../components/gestor/GestorHome.vue");
const EncuentaSatisfaccion = () => import("../components/usuario/CalificacionServicio.vue");
//GESTOR-REGIONAL
const GestorRegionalHome = () => import("../components/regional/GestorRegionalHome.vue");
//GESTOR-PROMOTOR
const PromotorHome = () => import("../components/promotor/PromotorHome.vue");
//const GestorClave = () => import("../components/gestor/GestorMantClave");
//Admin
const AdminLogin = () => import("../components//admin/AdminLogin.vue");
const AdminHome = () => import("../components/admin/AdminHome.vue");

export const routes = [
    {
        name: "login",
        path: "/login",
        component: Login,
    },
    {
        name: "loginempresa",
        path: "/loginempresa",
        component: EmpresaLogin,
    },
    {
        name: "tuempleo-admin",
        path: "/tuempleo-admin",
        component: AdminLogin,
    },
    {
        name: "reset-password-request",
        path: "/reset-password-request",
        component: ResetPasswordRequest,
    },
    {
        name: "reset-password",
        path: "/reset-password/:email",
        component: ResetPassword,
    },
    {
        name: "registro",
        path: "/registro",
        component: Registro,
    },
    {
        name: "registroempresa",
        path: "/registroempresa",
        component: EmpresaRegistro,
    },
    {
        name: "registrosucursal",
        path: "/registrosucursal",
        component: SucursalRegistro,
    },
    {
        name: "home",
        path: "/",
        component: LandingPage,
    },
    {
        name: "usrhome",
        path: "/usrhome",
        component: UsuarioHome,
        beforeEnter: (to, form, next) =>{
            if(isAuth){
                next()
            } else {
                next("/login")
            }
        }
    },
    {
        name: "empresahome",
        path: "/empresahome",
        component: EmpresaHome,
        beforeEnter: (to, form, next) =>{
            if(isAuth){
                next()
            } else {
                next("/loginempresa")
            }
        }
    },
    {
        name: "gestorhome",
        path: "/gestorhome",
        component: GestorHome,
        beforeEnter: (to, form, next) =>{
            if(isAuth){
                next()
            } else {
                next("/loginempresa")
            }
        }
    },
    {
        name: "regionalhome",
        path: "/regionalhome",
        component: GestorRegionalHome,
        beforeEnter: (to, form, next) =>{
            if(isAuth){
                next()
            } else {
                next("/tuempleo-admin")
            }
        }
    },
    {
        name: "promotorhome",
        path: "/promotorhome",
        component: PromotorHome,
        beforeEnter: (to, form, next) =>{
            if(isAuth){
                next()
            } else {
                next("/loginempresa")
            }
        }
    },
    {
        name: "adminhome",
        path: "/adminhome",
        component: AdminHome,
        beforeEnter: (to, form, next) =>{
            if(isAuth){
                next()
            } else {
                next("/tuempleo-admin")
            }
        }
    },
    {
        name: 'listing',
        path: '/listing/:id',
        component: OfertaInfo,
        beforeEnter: (to, form, next) =>{
            if(isAuth){
                next()
            } else {
                next("/login")
            }
        }
    },
    {
        name: 'eventoqr',
        path: '/eventoqr/:id',
        component: EventoParticipacionQR,
        beforeEnter: (to, form, next) =>{
            if(isAuth){
                next()
            } else {
                next("/login")
            }
        }
    },
    {
        path: "*",
        component: NotFound,
    },
    
    //Ruta componente desarrollo
    {
        name: "dev",
        path: "/dev",
        component: Developer,
        beforeEnter: (to, form, next) =>{
            if(isAuth){
                next()
            } else {
                next("/login")
            }
        }
    },
    //ruta calificacion servicio o encuesta de satisfaccion
    {
        name: 'encuenta',
        path: '/encuesta/:id',
        component: EncuentaSatisfaccion,
        beforeEnter: (to, form, next) =>{
            if(isAuth){
                next()
            } else {
                next("/login")
            }
        }
    },
];

function isAuth() {
    axios.get('/api/athenticated').then(()=>{
        return true
    }).catch(()=>{
        return false
    })    
}



window._ = require('lodash');

//Importamos VUE
import vue from 'vue';
window.Vue = vue;
window.Vue = require('vue').default;

//Importamos Axios
import VueAxios from 'vue-axios';
import axios from 'axios';
Vue.use(VueAxios, axios);
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true;

//Importamos y configuramos el Vue-router
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import {routes} from './routes';
const router = new VueRouter({
    mode: 'history',
    routes: routes
});

//Vue-Vuietify
import vuetify from '../plugins/vuetify';
Vue.use(vuetify);

//Importamos vue-session
import VueSession from 'vue-session'
Vue.use(VueSession)

//Importamos moment para fechas
import moment from 'moment';
Vue.filter('formatShortDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});

//CK Editor, editor de texto enrioquecido
import CKEditor from 'ckeditor4-vue';
Vue.use(CKEditor)

//Sweetalert2 
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

//Select2 (para poder capturar el change event)
Vue.directive('select', {
    twoWay: true,
    bind: function (el, binding, vnode) {
      $(el).select2().on("select2:select", (e) => {
        // v-model looks for
        //  - an event named "change"
        //  - a value with property path "$event.target.value"
        el.dispatchEvent(new Event('change', { target: e.target }));
      });
    },
    componentUpdated: function(el, me) {
      // update the selection if the value is changed externally
      $(el).trigger("change");
    }
  });
  
  //Definimos nuestra app de Vue
  import App from '../components/App.vue';
  if(document.getElementById("app")){
      const app = new Vue({
          vuetify: vuetify,
          el: '#app',
          router: router,
          render: h => h(App)
        });
    }

//Definimos nuestro home de Vue
import Home from '../components/Home.vue';
if(document.getElementById("home")){
    const home = new Vue({
        vuetify: vuetify,
        el: '#home',
        router: router,
        render: h => h(Home)
    });
}